import Layout from '../../components/Layout';
import Helmet from 'react-helmet';
import React, { useState } from "react";
import { useSelector } from 'react-redux';

import LiffAutoExternalOpener from '../../components/LiffAutoExternalOpener';
import QRCodeStep from '../../components/NHSO/QRCodeStep';
import NHSOStep from '../../components/NHSO/NHSOStep';
import FinishedStep from '../../components/NHSO/FinishedStep';
import SaluberNHSOQRCode from '../../assets/images/partner/nhso/saluber/SaluberNHSOQRCode.jpg';

export default ({}) => {
  const session = useSelector(state => state.session);
  const [step, setStep] = useState(1);

  const openNHSOLink = () => {
    const url = 'line://liff.line.me/1569357154-QaZqaL2B';
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const showFinishedStep = () => {
    const finishedStep = 4;
    setStep(finishedStep);
  };

  const nextStep = () => {
    const nextStep = step + 1;

    if (nextStep === 3) {
      openNHSOLink();
      showFinishedStep();
    } else {
      setStep(nextStep);
    }
  }

  return (
    <Layout hideAppBar>
      <Helmet>
        <title>สปสช</title>
      </Helmet>
      {step == 1 && (
        <QRCodeStep
          qrCodeURL={SaluberNHSOQRCode}
          topText='1.กรุณากรุณาแคปหน้าจอ QR code นี้เพื่อนำไปใช้สแกนขอรหัสเข้ารับบริการ'
          title='QR code คลินิกสุขสบาย'
          saveButtonText='บันทึกรูปภาพเพื่อไปต่อ'
          nextStep={nextStep}
        />
      )}
      {step === 2 && (
        <NHSOStep nextStep={nextStep} />
      )}
      {step === 4 && <FinishedStep />}
      
      {session.user && <LiffAutoExternalOpener /> }
    </Layout>
  )
}