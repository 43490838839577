import React from 'react';
import * as Liff from '../../../apis/liff';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: "30px",
    paddingBottom: "40px",
    textAlign: "center",
    height: "100vh",
  },
  topText: {
    color: "#212121",
    padding: "32px",
  },
  nextButton: {
    fontSize: "1rem",
    borderRadius: "20px",
    backgroundColor: "#4677a8",
    minWidth: "150px",
  }
}));

export default () => {
  const classes = useStyles();

  const closeWindow = () => {
    if (Liff.isInClient()) {
      Liff.closeWindow();
    } else {
      window.location.href = 'me.moronline://exit';
    }
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.container}
      spacing={1}
    >
      <Grid item xs={12}>
        <Typography
          variant="h6"
          gutterBottom
          className={classes.topText}
        >
          ขั้นตอนขอสิทธิ์เบิกจ่ายเรียบร้อยแล้ว โปรดปิดหน้านี้
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.nextButton}
          onClick={closeWindow}
        >
          ปิดหน้านี้
        </Button>
      </Grid>
    </Grid>
  );
};
