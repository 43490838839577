import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SaluberNHSOInstructionImage2 from '../../../assets/images/partner/nhso/saluber/SaluberNHSOInstructionStep2.svg';
import SaluberNHSOInstructionImage3 from '../../../assets/images/partner/nhso/saluber/SaluberNHSOInstructionStep3.svg';
import SaluberNHSOInstructionImage4 from '../../../assets/images/partner/nhso/saluber/SaluberNHSOInstructionStep4.svg';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: "30px",
    paddingBottom: "40px",
    textAlign: "center",
  },
  nextButton: {
    "& button": {
      fontSize: "1rem",
      borderRadius: "20px",
      backgroundColor: "#4677a8",
      minWidth: "150px",
    }
  }
}));

export default ({nextStep = null}) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" alignItems='center' className={classes.container} spacing={1}>
      <Grid item xs={12}>
        <img src={SaluberNHSOInstructionImage2} />
      </Grid>
      <Grid item xs={12}>
        <img src={SaluberNHSOInstructionImage3} />
      </Grid>
      <Grid item xs={12}>
        <img src={SaluberNHSOInstructionImage4} />
      </Grid>
      <Grid item xs={12} className={classes.nextButton}>
        <Button
          variant="contained"
          color="primary"
          onClick={nextStep}
        >
          ต่อไป
        </Button>
      </Grid>
    </Grid>
  );
};
