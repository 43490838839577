import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import SuccessIcon from '../../../../assets/images/icons/success_check_green.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
  },
  nextButton: {
    fontSize: "1rem",
    minWidth: "150px",
    borderRadius: "20px",
    backgroundColor: "#4677a8",
    marginTop: "30px",
    marginBottom: "20px",
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ({ isOpen, onClose = null }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(isOpen);

  useEffect(() => {
    setOpen(open);
  }, [open]);


  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setOpen(false);
    }
    if (onClose) onClose();
  };

  return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        disableBackdropClick
        disableEscapeKeyDown
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Grid 
            container
            direction="row"
            justifyContent="center"
            alignItems='center'
            className={classes.container}
            spacing={2}
          >
            <Grid item xs={12}>
              <img src={SuccessIcon} />
              <Typography
                variant="h5"
                style={{ textAlign: "center", color: "#212121" }}
              >
                บันทึกรูปภาพสำเร็จ
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                style={{ textAlign: "center", color: "#212121" }}
              >
                กรุณากด ”ต่อไป”
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                className={classes.nextButton}
                onClick={handleClose}
              >
              ต่อไป
            </Button>
          </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
  );
}
