import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DownloadedDialog from './DownloadedDialog';

function isFullFeaturedBrowser() {
  if (typeof window !== 'undefined') {
    const userAgent = navigator.userAgent || window.opera;
    return /Chrome|Safari|Firefox|Edge/.test(userAgent);
  }
  return false;
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: '30px',
    textAlign: 'center',
  },
  topText: {
    color: "#212121",
  },
  title: {
    padding: theme.spacing(2),
    border: "1px solid #8DD3FF",
    borderRadius: "20px",
    color: "#212121",
    "& h5": {
      fontWeight: 800,
    }
  },
  qrcode: {
    width: "100%",
    height: "auto",
  },
  downloadButton: {
    '& button': {
      fontSize: "1rem",
      borderRadius: "20px",
      backgroundColor: "#4677a8",
      minWidth: "150px",
    }
  }
}));

export default ({qrCodeURL = null, topText = '', title = '', saveButtonText = '', nextStep = null}) => {
  const classes = useStyles();
  const [qrCodeURLState, setQRCodeURLState] = useState(qrCodeURL);
  const [showDownloadedPopup, setShowDownloadedPopup] = useState(false);

  React.useEffect(() => {
    setQRCodeURLState(qrCodeURL);
  }, [qrCodeURL, showDownloadedPopup]);

  const handleDownloadSVG = () => {
    const img = new Image();
    img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        const imageUrl = canvas.toDataURL('image/png');
        const downloadLink = document.createElement('a');
        downloadLink.href = imageUrl;
        downloadLink.download = 'nhso_qr_code.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    img.onerror = () => {
        console.error('Error loading SVG into image');
    };

    img.src = qrCodeURL;
  };

  const handleDownloadClick = () => {
    if (isFullFeaturedBrowser()) {
      handleDownloadSVG();
      setShowDownloadedPopup(true);
    } else {
      handleNextStep() 
    }
  }

  const handleNextStep = () => {
    if (nextStep) nextStep();
  }

  return (
    <>
      <Grid container alignItems='center' className={classes.container} spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            gutterBottom
            className={classes.topText}
          >
            {topText}
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            component="div"
            m={1}
            p={4}
            className={classes.title}
          >
            <Typography
              variant="h5"
              style={{ textAlign: "center", }}
            >
              {title}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.qrcode}>
          <img src={qrCodeURLState} />
        </Grid>
        <Grid item xs={12} className={classes.downloadButton}>
        {isFullFeaturedBrowser()
          ?
            <Button
              variant="contained"
              color="primary"
              endIcon={<CloudDownloadIcon />}
              onClick={handleDownloadClick}
            >
              {saveButtonText}
            </Button>
          :
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownloadClick}
            >
              ต่อไป
            </Button>
          }
        </Grid>
      </Grid>
      {showDownloadedPopup && <DownloadedDialog
        isOpen={showDownloadedPopup}
        onClose={handleNextStep} />}
    </>
  );
};
