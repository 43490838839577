import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUrlParams } from '../../helpers/location';

const LiffAutoExternalOpener = () => {
    const session = useSelector(state => state.session);
    const liff = typeof window !== 'undefined' ? window.liff: null;

    useEffect(() => {
        if (!liff) return;

        const queryParams = new URLSearchParams(window.location.search);
        const liffId = queryParams.get('liffId');

        liff.init({ liffId: liffId })
            .then(() => {
                console.log('LIFF initialized!');
                openCurrentUrlInExternalBrowser();
            })
            .catch((err) => {
                console.error('LIFF initialization failed', err);
            });
    }, []);

    const openCurrentUrlInExternalBrowser = () => {
        const origin = window.location.origin;
        const pathname = window.location.pathname;
        const appToken = session.user.token;
        const params = getUrlParams(window.location.search);
        const { liffId, ...excludeLiffIdParams } = params;
        const additionalParams = { "appToken": appToken };
        Object.assign(excludeLiffIdParams, additionalParams);
        const queryString = new URLSearchParams(excludeLiffIdParams).toString();
        const urlWithParams = `${origin}${pathname}?${queryString}`
        if (liff.isInClient()) {
            liff.openWindow({
                url: urlWithParams,
                external: true
            });
        } else {
            // If not in LINE client or if it's a desktop browser, redirect normally
            window.open(window.location.href, '_blank');
        }
    };

    return null;
};

export default LiffAutoExternalOpener;
